import { InputAdornment, TextField } from "@mui/material";
import Icon from "react-crypto-icons";

const buttonStyle =
{
	"height": "42px",
	"lineHeight": "42px",
	"background": "linear-gradient(111.85deg, #FB87FF -23.82%, #AE44ED 119.4%)",
	"boxShadow": "0px 10px 20px rgba(219, 134, 255, 0.66)",
	"borderRadius": "44px",
	"fontFamily": "Rubik",
	"fontStyle": "normal",
	"fontWeight": "normal",
	"fontSize": "14px",
	"textAlign": "center",
	"textTransform": "uppercase",
	"color": "#FFFFFF"
}
const BuyButton = ({
  reservePrice,
  onClick,
}) => {
	return <>
	  <div role="button"
	    style={{
	      ...buttonStyle,
	      "marginTop": "15px",
        "fill": "white",
	    }}
	    onClick={onClick}>
	    Buy NFT&nbsp;<Icon size={12} name="algo" />&nbsp;{reservePrice}
	  </div>
	</>
      }
export default BuyButton;