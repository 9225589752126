import React, { useEffect, useState } from "react"
import {
  bigNumberToNumberFromSome,
  formatCurrencyFromSome,
} from '../functions'
import moment from "moment"
const IndicatorLoader = ({
  id,
  stdlib,
  children,
  v,
  initialState,
}) => {
  const [state, setState] = useState(initialState)
  console.log({
    id,
    stdlib,
    children,
    v,
    initialState,
  })
  useEffect(() => {
    if (state.isReservePriceMet) return
    if (state.isClosed) return
    let interval = setInterval(() => {
      Promise.all([
        v.currentPrice(),
        v.endSecs(),
      ])
        .then(async ([
          cp,
          endSecs,
        ]) => {
          let fCp = formatCurrencyFromSome(stdlib, cp)
          let fEndSecs = bigNumberToNumberFromSome(stdlib, endSecs)
          let isClosed = parseInt(fEndSecs) < moment().unix()
          let isReservePriceMet = parseFloat(state.reservePrice) < parseFloat(fCp)
          if (isReservePriceMet) {
            setState({
              ...state,
              isReservePriceMet: true
            })
            clearInterval(interval)
            return
          }
          else if(isClosed) {
            setState({
              ...state,
              isClosed: true
            })
            clearInterval(interval)
            return
          }
        })
    }, 4000);
    return () => clearInterval(interval)
  }, [])
  return (
    React.Children.map(children, (child, i) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child,
          { ...state, key: id });
      }
      return child;
    })
  )
}
export default IndicatorLoader;